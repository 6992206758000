import { FormType } from '@/constant/form';
import unitNumber from '@/utils/unitNumber';
import { STATUS, RECEIPT_ORDER_COPY_CODE_TYPE, IS_RETURN } from './constant';

export const DICTIONARY = {
  ALLOCATION: 'allocation',
  AUXILIARYUNITCOUNT: 'auxiliaryUnitCount',
};
export const INFO = [{
  label: '货品编码：',
  prop: 'goodsCode',
}, {
  label: '货品名称：',
  prop: 'goodsName',
}, {
  label: '规格：',
  prop: 'spec',
}, {
  label: '换算关系：',
  prop: 'conversionRate',
}, {
  label: '单板数量：',
  prop: 'veneerWeightStr',
}];
export const TABLECOLUMN = (batchFields, configAccept, form) => {
  const batchS = batchFields.map((item) => {
    // 是否退货
    if (item.fieldCode === 'is_return') {
      return {
        ...item,
        component: 'select',
        options: [{
          value: IS_RETURN.TRUE,
          label: '是',
        }, {
          value: IS_RETURN.FALSE,
          label: '否',
        }],
      };
    } if (item.fieldCode === 'batch_reverse') {
      return {
        ...item,
        component: 'radio',
        options: [{
          value: IS_RETURN.TRUE,
          label: '是',
        }, {
          value: IS_RETURN.FALSE,
          label: '否',
        }],
      };
    }
    return item;
  });
  const batch = (batchField) => ({
    date: {
      ...batchField,
      label: batchField.fieldName,
      prop: batchField.fieldCode,
      width: 180,
      componentType: FormType.DATE_PICKER,
      valueFormat: 'YYYY-MM-DD',
      default: batchField.paddingValue || '',
    },
    input: {
      ...batchField,
      label: batchField.fieldName,
      prop: batchField.fieldCode,
      width: 120,
      componentType: FormType.INPUT,
      default: batchField.paddingValue || '',
    },
    radio: {
      ...batchField,
      label: batchField.fieldName,
      prop: batchField.fieldCode,
      width: 240,
      componentType: 'el-radio-group',
      default: batchField.paddingValue || '',
    },
    select: {
      ...batchField,
      label: batchField.fieldName,
      prop: batchField.fieldCode,
      width: 120,
      componentType: 'el-select',
      default: batchField.paddingValue || '',
    },
  });
  const base = [{
    label: '货品质量',
    prop: 'quality',
    width: 120,
    componentType: FormType.SELECT,
    formatter: (current, row, flag) => {
      // 查看和编辑取值不同。查看直接展示，编辑数字字典查询
      if (flag && row.quality) {
        return row.quality;
      }
      const option = current.options.find((item) => item.value === current.default);
      return option?.label ?? current.default;
    },
    ...configAccept,
  }, {
    label: '实收数量',
    prop: DICTIONARY.ALLOCATION,
    width: 240,
  }, {
    label: '容器号',
    prop: 'containerCode',
    width: 180,
    componentType: FormType.SELECT,
    options: [],
    filterable: true,
    remote: true,
  }];
  if (form.copyCode && form.receiptOrderCopyCodeType === RECEIPT_ORDER_COPY_CODE_TYPE.GOODS) {
    return [
      ...batchS.map(((item) => batch(item)[item.component] || batch(item).input)),
      ...base,
      {
        label: '辅助单位',
        prop: 'auxiliaryUnitCount',
        width: 150,
        componentType: FormType.INPUT,
      },
    ];
  }
  return [
    ...batchS.map(((item) => batch(item)[item.component] || batch(item).input)),
    ...base,
  ];
};

export const selectTabsColumn = [{
  label: '标签号(一次性容器码)',
  prop: 'onceContainerCode',
  minWidth: 180,
}, {
  label: '绑定数量',
  prop: 'amount',
  minWidth: 180,
  formatter: (row) => unitNumber.calculate({
    ...row,
    smallUnitNumber: row.amount,
  }).txt,
}, {
  label: '验收状态',
  prop: 'status',
  minWidth: 100,
  formatter: ({ status }) => STATUS[status],
}];
