<template>
  <nh-drawer
    v-model="dialogVisible"
    title="验收"
    :before-close="handleClose"
    destroy-on-close
    size="1112px"
    :track="{
      name_zh: `入库管理/验收/验收页/抽屉-验收${isEdit?'': '详情'}`,
      trackName: $route.path
    }"
  >
    <el-form
      v-loading="loading.getDetail"
      label-width="100"
      :model="form"
    >
      <div class="header-component">
        <el-form-item
          v-for="(item) in INFO"
          :key="item.prop"
          :label="item.label"
          class="form-item"
        >
          <span>{{ form[item.prop] }}</span>
        </el-form-item>
      </div>

      <nh-button
        v-if="isEdit"
        type="primary"
        class="margin-bottom-16"
        @click="handleAddRow"
      >
        添加一行
      </nh-button>
      <nh-button
        v-if="isEdit"
        v-loading="loading.handleSelectTabs"
        type="primary"
        class="margin-bottom-16"
        @click="handleSelectTabs"
      >
        待验收
      </nh-button>
      <el-form
        ref="formTableRef"
        label-width="100"
        :model="formData"
      >
        <el-table
          :data="formData.tableData"
          class="width-100"
          height="340"
        >
          <el-table-column
            type="index"
            width="50"
          />
          <el-table-column
            v-for="item in TABLECOLUMN(batchFields, configAccept, form)"
            :key="item.label"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
          >
            <template #default="scope">
              <div v-if="item.prop === DICTIONARY.ALLOCATION" class="table-input">
                <!-- 实收数量 -->
                <template v-if="scope.row.bigUnit !== scope.row.smallUnit">
                  <el-form-item
                    v-if="isEdit"
                    label=""
                    :error="scope.row.error"
                  >
                    <el-input-number
                      v-model="scope.row.bigUnitNumber"
                      step-strictly
                      :min="0"
                      size="mini"
                      :controls="false"
                      placeholder="请输入"
                      :step="1"
                      :precision="0"
                      @blur="handleBlurNum(scope.row,scope.$index)"
                    />
                  </el-form-item>
                  <span v-else>{{ scope.row.bigUnitNumber }}</span>
                  {{ scope.row.bigUnit }}
                </template>
                <el-form-item
                  v-if="isEdit"
                  label=""
                  :error="scope.row.error"
                >
                  <el-input-number
                    v-model="scope.row.smallUnitNumber"
                    step-strictly
                    :min="0"
                    size="mini"
                    :controls="false"
                    placeholder="请输入"
                    :step="0.001"
                    :precision="3"
                    @blur="handleBlurNum(scope.row,scope.$index)"
                  />
                </el-form-item>
                <span v-else>{{ scope.row.smallUnitNumber }}</span>
                {{ scope.row.smallUnit }}
              </div>
              <div v-else-if="item.prop === DICTIONARY.AUXILIARYUNITCOUNT" class="table-input">
                <!-- 辅助数量 -->
                <el-form-item
                  v-if="isEdit"
                  label=""
                  :error="scope.row.error"
                >
                  <el-input-number
                    v-model="scope.row.auxiliaryUnitCount"
                    step-strictly
                    :min="0"
                    size="mini"
                    :controls="false"
                    placeholder="请输入"
                    :step="0.001"
                    :precision="3"
                    @blur="handleBlurNum(scope.row,scope.$index)"
                  />
                </el-form-item>
                <span v-else>{{ scope.row.auxiliaryUnitCount }}</span>
                {{ form.auxiliaryUnit }}
              </div>
              <div v-else-if="NoSlotComponents.includes(item.componentType)" class="table-input">
                <el-form-item
                  label=""
                  :rules="[
                    {required: isBatchAttrRequired(item.prop),message: ' ',trigger: 'blur',}
                  ]"
                  :prop="'tableData.' + scope.$index + '.' + item.prop"
                >
                  <component
                    :is="item.componentType"
                    v-if="isEdit && !item.readOnly"
                    v-model="scope.row[item.prop]"
                    v-bind="item"
                    size="mini"
                    class="table-date"
                    :loading="loading.onSearchOptions"
                    :disabled-date="(date) => handleDisbaledDate(date, scope.$index, item)"
                    @change="handleTableChange(scope.$index, item)"
                  />
                  <span v-else>
                    {{ item.formatter ? item.formatter(item) : scope.row[item.prop] }}
                  </span>
                </el-form-item>
              </div>
              <div v-else class="table-input">
                <el-form-item
                  label=""
                  :rules="[
                    {required: isBatchAttrRequired(item.prop),message: ' ',trigger: 'blur',}
                  ]"
                  :prop="'tableData.' + scope.$index + '.' + item.prop"
                >
                  <component
                    :is="item.componentType"
                    v-if="isEdit && !item.readOnly"
                    v-model="scope.row[item.prop]"
                    v-bind="item"
                    size="mini"
                    class="table-date"
                    :loading="loading.onSearchOptions"
                    :remote-method="(keyword) => {
                      onSearchOptions(keyword, item)
                    }"
                    @change="handleTableChange(scope.$index, item)"
                  >
                    <template v-if="item.componentType === FormType.SELECT">
                      <el-option
                        v-for="option in item.options"
                        :key="option.value || option.label"
                        :label="option.label"
                        :value="option.value"
                      />
                    </template>
                    <template v-if="item.componentType === 'el-radio-group'">
                      <el-radio
                        v-for="option in item.options"
                        :key="option.value || option.label"
                        class="radio"
                        :label="option.value"
                      >
                        {{ option.label }}
                      </el-radio>
                    </template>
                  </component>
                  <span v-else>
                    {{ item.formatter
                      ? item.formatter(item, scope.row, !isEdit) : scope.row[item.prop] }}
                  </span>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isEdit"
            width="80"
            label="操作"
          >
            <template #default="scope">
              <nh-button type="text" @click="handleDelete(scope.row)">
                删除
              </nh-button>
            </template>
          </el-table-column>
        <!-- / 操作栏 -->
        </el-table>
      </el-form>
    </el-form>
    <template #footer>
      <div class="footer">
        <div class="flex_row">
          <div class="flex_1 fields">
            <span class="field">预约数量：{{ form.bookingNumStr }}</span>
            <span class="field">已验收量：{{ form.acceptanceNumStr }}</span>
            <span class="field">本次实收合计：{{ totalAcceptedCountStr }}</span>
          </div>
          <div>
            <nh-button
              v-if="isEdit"
              :loading="loading.handleSubmit"
              type="primary"
              @click="handleSubmit"
            >
              确 定
            </nh-button>
            <nh-button
              type="grey"
              @click="onClose"
            >
              {{ `${isEdit ? '取 消': '关 闭'}` }}
            </nh-button>
          </div>
        </div>
      </div>
    </template>
    <select-tabs ref="selectTabsDialog" @onSelect="handleAddLists" />
  </nh-drawer>
</template>
<script>
import moment from 'moment';
import { localStorageGet, localStorageSet } from '@/utils/localStorage';
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import { YMD } from '@/constant/timeFormat';
import { FormType, NoSlotComponents } from '@/constant/form';
import { StoreBillType } from '@/constant/task';
import { RECEIPT_AND_GOODS } from '@/constant/storage';
import { warehouseContainerPage } from '@/views/base/warehouse-about/container/api';
import {
  DICTIONARY, INFO, TABLECOLUMN,
} from './fileds';
import {
  taskDetail, taskCommit, receiptOrderRuleDetail, acceptanceProductDateCheck,
  acceptanceContainerList,
} from '../api';
import {
  RECEIPT_ORDER_COPY_CODE_TYPE, IS_RETURN,
  ARRIVAL_TEM,
} from './constant';

import SelectTabs from './selectTabs.vue';

export default {
  name: 'InWarehouseAcceptanceDetail',
  components: {
    SelectTabs,
  },
  mixins: [loadingMixin],
  emits: ['onSucceed'],
  data() {
    return {
      orderInfo: {},
      DICTIONARY,
      INFO,
      TABLECOLUMN,
      FormType,
      NoSlotComponents,
      RECEIPT_ORDER_COPY_CODE_TYPE,
      isEdit: false,
      dialogVisible: false,
      taskId: null,
      form: {},
      formData: {
        tableData: [],
      },
      loading: {
        getDetail: false,
        handleSubmit: false,
        onSearchOptions: false,
        handleSelectTabs: false,
      },
      batchFields: [],
      configAccept: {},
      latestProductDateList: null,
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
    // 本次实收合计，格式：零单位数量
    totalAcceptedCount() {
      // 本次实收合计
      let acceptanceNumInTable = 0;

      this.formData.tableData.forEach((item) => {
        acceptanceNumInTable += unitNumber.getTotal(item);
      });

      return acceptanceNumInTable;
    },
    // 本次实收合计，格式：带单位的字符串
    totalAcceptedCountStr() {
      const { bigUnit, smallUnit, conversionRate } = this.form;
      const totalAcceptedCountStr = unitNumber.calculate({
        smallUnitNumber: this.totalAcceptedCount,
        bigUnit,
        smallUnit,
        conversionRate,
      }).txt;

      return totalAcceptedCountStr;
    },
    // 未验收数量 = 预约数量 - 已收数量 - 本次实收合计
    notAcceptedCount() {
      return this.form.bookingNum - this.form.acceptanceNum - this.totalAcceptedCount;
    },
    isBatchAttrRequired() {
      return (prop) => this.batchFields.some((item) => item.fieldCode === prop);
    },
  },
  methods: {
    setReceiptAndgoodsKey(receiptNo, goodsId) {
      return receiptNo + goodsId;
    },
    async init(row, edit) {
      this.dialogVisible = true;
      this.isEdit = !!edit;
      this.orderInfo = { ...row };
      this.taskId = row.taskId;
      this.batchFields = [];
      this.formData.tableData = [];
      this.form = {};
      await this.getDetail(row);
      // 设置货品质量
      this.setGoodsQuality();
    },
    async getDetail(row) {
      const params = {
        taskId: row.taskId,
        receiptOrderNo: row.receiptOrderNo,
      };
      if (!this.isEdit) {
        params.needTaskRecord = true;
      }
      const { batchFields, taskSubContents, ...form } = await taskDetail(params);
      // 判断是否是按箱抄码
      if (
        form.copyCode && form.receiptOrderCopyCodeType === this.RECEIPT_ORDER_COPY_CODE_TYPE.BOX
      ) {
        await this.$alert('该品需要按箱抄码，请使用PDA进行验收', '温馨提示', {
          confirmButtonText: '确定',
        });
        this.dialogVisible = false;
        return;
      }
      // 设置批属性
      this.setBatchFields(batchFields);

      this.formData.tableData = taskSubContents.map((item) => {
        const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate({
          ...item,
          smallUnitNumber: item.acceptanceValue,
        });
        const { quality } = item;
        const list = {
          ...item,
          bigUnitNumber,
          smallUnitNumber,
          quality: this.isEdit ? item.quality : this.GOODS_QUALITY
            .find((q) => q.value === quality)?.label,
        };
        item.batchFields.forEach((batchField) => {
          // 是否退货 如果未指定批属性，则当单据类型为退货入库单时，默认值为是；其他单据类型时默认值为否
          if (this.isEdit && batchField.fieldCode === 'is_return' && !batchField.paddingValue) {
            list[batchField.fieldCode] = (form.receiptType === StoreBillType.RETURNED
              || form.receiptType === StoreBillType.REJECTED) ? IS_RETURN.TRUE : IS_RETURN.FALSE;
          } else if ('batch_reverse' in list && list.productDate && form.lastProductDate && this.isEdit) {
            // 批次倒挂 如果当前品已有库存且录入的生产日期比库存中最大的生产日期小时，默认值为是；否则默认值为否。当生产日期或到期日期变更时重新计算默认值。
            list.batch_reverse = moment(list.productDate).isBefore(form.lastProductDate, 'day')
              ? IS_RETURN.TRUE : IS_RETURN.FALSE;
          } else if (this.isEdit && ARRIVAL_TEM in list && (list[ARRIVAL_TEM] === null || list[ARRIVAL_TEM] === '')) {
            // 数字，支持负数，支持2位小数。同一个入库单的同一个品，填写到货温度后每次进入该单该品的验收页面，到货温度都自动填充（未退出登录的情况下）。
            const key = this.setReceiptAndgoodsKey(form.receiptNo, form.goodsId);
            const receiptAndgoods = localStorageGet(RECEIPT_AND_GOODS);
            if (receiptAndgoods) {
              list[ARRIVAL_TEM] = receiptAndgoods[key];
            }
          } else {
            list[batchField.fieldCode] = batchField.paddingValue;
          }
        });

        return list;
      });
      const veneerWeight = this.calculateVeneerWeight(form);

      this.form = {
        ...form,
        ...veneerWeight,
      };
      // 获取最新生产日期
      const result = await acceptanceProductDateCheck({ goodsIds: [form.goodsId] });
      this.latestProductDateList = result[0]?.latestProductDate;
    },
    handleTableChange(index, item) {
      const row = this.formData.tableData[index];
      if (item.prop === 'expire_date') {
        // 如果到期日期改变,检查生产日期
        this.checkProductDate(index);
      }
      if (item.prop === 'productDate') {
        // 如果生产日期改变,检查到期日期
        this.checkExpireDate(index);
      }
      if (this.form.lastProductDate) {
        if ((item.prop === 'productDate' || item.prop === 'expire_date') && row.productDate) {
          // 如果生产日期 或到期日期改变 且 生产日期有值, 重新计算批次倒挂
          row.batch_reverse = moment(row.productDate).isBefore(this.form.lastProductDate, 'day')
            ? IS_RETURN.TRUE : IS_RETURN.FALSE;
        }
      }
      if (item.prop === ARRIVAL_TEM) {
        // 数字，支持负数，支持2位小数。同一个入库单的同一个品，填写到货温度后每次进入该单该品的验收页面，到货温度都自动填充（未退出登录的情况下）。
        const reg = /^[+-]?[0-9]+.?[0-9]*$/;
        let num = reg.test(row[ARRIVAL_TEM]) ? parseInt((row[ARRIVAL_TEM] * 100), 10) / 100 : null;
        if (num > 1000 || num < -1000) num = null;
        if (num !== null && num !== '') {
          const receiptAndgoods = localStorageGet(RECEIPT_AND_GOODS) || {};
          const key = this.setReceiptAndgoodsKey(this.form.receiptNo, this.form.goodsId);
          localStorageSet(RECEIPT_AND_GOODS, {
            ...receiptAndgoods,
            [key]: num,
          });
        }
        row[ARRIVAL_TEM] = num;
      }
    },
    // 当货品的批属性有到期日期，没有生产日期时，自动根据到期日期-保质期计算出生产日期
    checkProductDate(index) {
      const row = this.formData.tableData[index];

      if (!row.expire_date) {
        return;
      }

      row.productDate = this.calculateProductDate({
        expireDate: row.expire_date,
      });
    },
    // 当货品的批属性有生产日期，没有到期日期时，自动根据生产日期+保质期计算出到期日期
    checkExpireDate(index) {
      const row = this.formData.tableData[index];

      if (!row.productDate) {
        return;
      }
      row.expire_date = this.calculateExpireDate({
        productDate: row.productDate,
      });
    },
    calculateVeneerWeight({
      storageRuleExt, conversionRate, bigUnit,
    }) {
      const { veneerNum = '' } = storageRuleExt || {};
      // 零单位单板数量
      const veneerWeightBaseSmallUnit = unitNumber.getTotal({
        bigUnitNumber: veneerNum, // 整单位单板数量
        conversionRate,
      });

      return {
        veneerWeightBaseSmallUnit,
        veneerWeightStr: `${veneerNum}${bigUnit}`,
      };
    },
    // 业务逻辑：根据批此号配置，拆分批此号属性
    setBatchFields(batchFields) {
      // 入库日期，入库单号不可编辑
      const readOnlyFields = ['store_date', 'store_no'];

      this.batchFields = batchFields.map((item) => ({
        ...item,
        // 当readOnly为true时，显示为文字，不可编辑
        readOnly: readOnlyFields.includes(item.fieldCode),
      }));
    },
    async setGoodsQuality() {
      this.configAccept = await this.getGoodsQuality();
    },
    // 业务逻辑：
    //  1、如果有指定货品质量（designatedGoodsQuality），使用指定货品质量，且不能编辑
    //  2、如果没有指定货品质量，使用默认货品质量(defaultGoodsQuality)，且可编辑
    async getGoodsQuality() {
      const readOnly = !!this.form.designatedGoodsQuality;
      const { designatedGoodsQuality, defaultGoodsQuality } = this.form;

      const goodsQualityColumn = {
        readOnly,
        options: await this.getGoodsQualityOptions(),
        default: readOnly ? designatedGoodsQuality : defaultGoodsQuality,
      };

      return goodsQualityColumn;
    },
    async getGoodsQualityOptions() {
      const { configAccept = {} } = await receiptOrderRuleDetail({
        receiptOrderId: this.orderInfo.receiptOrderId,
      });

      const { goodsQualityList = [] } = configAccept;
      const { GOODS_QUALITY = [] } = this.$dictionaryFiledMap;

      return GOODS_QUALITY?.filter((item) => goodsQualityList.includes(item.value));
    },
    async onSearchOptions(keyword, column) {
      if (column.prop === 'containerCode') {
        await this.searchContainerCodes(keyword, column);
      }
    },
    async searchContainerCodes(keyword, column) {
      const { records } = await warehouseContainerPage({ containerCode: keyword });
      const current = column;
      current.options = records.map(({ containerCode }) => ({
        value: containerCode,
        label: containerCode,
      }));
    },
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    handleAddRow() {
      const { bigUnitNumber, smallUnitNumber } = this.getFillCount(); // 获取填充的实收数量
      const { bigUnit, smallUnit, conversionRate } = this.form;
      const row = {
        bigUnit, smallUnit, conversionRate, bigUnitNumber, smallUnitNumber,
      };
      TABLECOLUMN(this.batchFields, this.configAccept, this.form)
        .forEach((item) => {
          row[item.prop] = item.default || null;
        });
      if (row.expire_date && !row.productDate) {
        row.productDate = this.calculateProductDate({
          expireDate: row.expire_date,
        });
      }
      if (!row.expire_date && row.productDate) {
        row.expire_date = this.calculateExpireDate({
          productDate: row.productDate,
        });
      }
      // 有最大天数 且有 倒挂字段 且 存在生产日期
      if (this.form.lastProductDate && 'batch_reverse' in row && row.productDate) {
        // 如果生产日期 或到期日期改变 且 生产日期有值, 重新计算批次倒挂
        row.batch_reverse = moment(row.productDate).isBefore(this.form.lastProductDate, 'day')
          ? IS_RETURN.TRUE : IS_RETURN.FALSE;
      }
      // 数字，支持负数，支持2位小数。同一个入库单的同一个品，填写到货温度后每次进入该单该品的验收页面，到货温度都自动填充（未退出登录的情况下）。
      if (ARRIVAL_TEM in row && !row[ARRIVAL_TEM]) {
        const receiptAndgoods = localStorageGet(RECEIPT_AND_GOODS);
        const key = this.setReceiptAndgoodsKey(this.form.receiptNo, this.form.goodsId);
        if (receiptAndgoods) {
          row[ARRIVAL_TEM] = receiptAndgoods[key];
        }
      }
      if ('is_return' in row) {
        row.is_return = (this.form.receiptType === StoreBillType.RETURNED
         || this.form.receiptType === StoreBillType.REJECTED) ? IS_RETURN.TRUE : IS_RETURN.FALSE;
      }
      this.formData.tableData.push(row);
    },
    handleAddLists(lists) {
      lists.forEach((list) => {
        const row = list;
        TABLECOLUMN(this.batchFields, this.configAccept, this.form)
          .forEach((item) => {
            row[item.prop] = item.default || null;
          });
        if (row.expire_date && !row.productDate) {
          row.productDate = this.calculateProductDate({
            expireDate: row.expire_date,
          });
        }
        if (!row.expire_date && row.productDate) {
          row.expire_date = this.calculateExpireDate({
            productDate: row.productDate,
          });
        }
        // 有最大天数 且有 倒挂字段 且 存在生产日期
        if (this.form.lastProductDate && 'batch_reverse' in row && row.productDate) {
        // 如果生产日期 或到期日期改变 且 生产日期有值, 计算批次倒挂
          row.batch_reverse = moment(row.productDate).isBefore(this.form.lastProductDate, 'day')
            ? IS_RETURN.TRUE : IS_RETURN.FALSE;
        }
        // 数字，支持负数，支持2位小数。同一个入库单的同一个品，填写到货温度后每次进入该单该品的验收页面，到货温度都自动填充（未退出登录的情况下）。
        if (ARRIVAL_TEM in row && !row[ARRIVAL_TEM]) {
          const receiptAndgoods = localStorageGet(RECEIPT_AND_GOODS);
          const key = this.setReceiptAndgoodsKey(this.form.receiptNo, this.form.goodsId);
          if (receiptAndgoods) {
            row[ARRIVAL_TEM] = receiptAndgoods[key];
          }
        }
        if ('is_return' in row) {
          row.is_return = (this.form.receiptType === StoreBillType.RETURNED
            || this.form.receiptType === StoreBillType.REJECTED) ? IS_RETURN.TRUE : IS_RETURN.FALSE;
        }
        const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate({
          ...list,
          smallUnitNumber: list.amount,
        });
        row.bigUnitNumber = bigUnitNumber;
        row.smallUnitNumber = smallUnitNumber;
        row.containerCode = list.onceContainerCode;
        this.formData.tableData.push(row);
      });
    },
    // 当货品的批属性有到期日期，没有生产日期时，自动计算 生产日期=到期日期-保质期
    calculateProductDate({
      expireDate,
    }) {
      // 生产日期 = 到期日期 - 保质期
      return moment(expireDate).subtract(this.orderInfo.shelfLife, 'days').format(YMD);
    },
    // 当货品的批属性有到期日期，没有生产日期时，自动计算 生产日期=到期日期-保质期
    calculateExpireDate({
      productDate,
    }) {
      // 生产日期 = 到期日期 - 保质期
      return moment(productDate).add(this.orderInfo.shelfLife, 'days').format(YMD);
    },
    // 新增时，获取自动填充的实收数量
    // 业务逻辑：如果未验收数量小于单板数量，使用未验收数量；否则使用单板数量数量
    getFillCount() {
      const { veneerWeightBaseSmallUnit } = this.form; // 零单位单板数量
      const useNotAcceptedCount = this.notAcceptedCount < veneerWeightBaseSmallUnit;

      let defaultCount = useNotAcceptedCount ? this.notAcceptedCount : veneerWeightBaseSmallUnit;

      if (defaultCount < 0) {
        defaultCount = 0;
      }

      return unitNumber.calculate({
        smallUnitNumber: defaultCount,
        conversionRate: this.form.conversionRate,
      });
    },
    handleDelete(item) {
      const index = this.formData.tableData.indexOf(item);
      if (index !== -1) {
        this.formData.tableData.splice(index, 1);
      }
    },
    handleInputNum(row, index) {
      const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate(row);
      const total = unitNumber.getTotal(row);
      const {
        bigUnitNumber: maxBigUnitNumber,
        smallUnitNumber: maxSmallUnitNumber,
      } = unitNumber.calculate({ conversionRate: row.conversionRate, smallUnitNumber: row.amount });
      if (total > row.amount) {
        this.formData.tableData[index].bigUnitNumber = maxBigUnitNumber || null;
        this.formData.tableData[index].smallUnitNumber = maxSmallUnitNumber || null;
        this.formData.tableData[index].allocateAmount = row.amount;
      } else {
        this.formData.tableData[index].bigUnitNumber = bigUnitNumber || null;
        this.formData.tableData[index].smallUnitNumber = smallUnitNumber || null;
        this.formData.tableData[index].allocateAmount = total;
      }
    },
    handleBlurNum(row, index) {
      const { bigUnitNumber, smallUnitNumber } = unitNumber.calculate(row);
      this.formData.tableData[index] = {
        ...this.formData.tableData[index],
        bigUnitNumber: bigUnitNumber || null,
        smallUnitNumber: (bigUnitNumber || smallUnitNumber) ? smallUnitNumber : null,
        error: (bigUnitNumber || smallUnitNumber) ? null : ' ',
      };
    },
    resetFields() {
      let flag = true;
      this.formData.tableData = this.formData.tableData.map((item) => {
        if (!item.bigUnitNumber && !item.smallUnitNumber) {
          flag = false;
          return {
            ...item,
            error: ' ',
          };
        }
        return {
          ...item,
          error: null,
        };
      });
      return flag;
    },
    async handleSubmit() {
      if (!this.resetFields()) {
        this.$message({
          type: 'error',
          message: '请输入实收数量',
        });
        return;
      }
      // 当已验数量 + 本次实收合计 > 预约数量
      const isOverCount = this.notAcceptedCount < 0;
      if (isOverCount) {
        if (!this.form.allowOvercharge) {
          // 不允许超收
          this.$message({
            type: 'error',
            message: '验收总数量不能大于预约数量',
          });
          return;
        }

        await this.$confirm('验收数量已超预约数量，是否确认验收？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      }
      // 比较生产日期与最新生产日期
      const isOverLatestProductDate = this.formData.tableData
        .some((item) => moment(this.latestProductDateList).diff(item.productDate) > 0);
      if (this.latestProductDateList && isOverLatestProductDate) {
        await this.$confirm('该生产日期早于库存最新生产日期，是否进行验收？', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      }
      const isOverstep = this.formData.tableData.some((item) => moment().diff(item.productDate, 'days') > this.form.dueInDays);
      if (this.form.dueInDays && isOverstep) {
        await this.$confirm(`该货品超出允收期[${this.form.dueInDays}天]，是否强制收货？`, '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      }
      await this.$refs.formTableRef.validate();
      const data = {
        ...this.form,
        subTaskId: this.taskId,
        acceptanceTaskCommits: this.formData.tableData.map((list) => {
          const batchAttr = this.batchFields.map((item) => ({
            attrId: item.id,
            attrName: item.fieldName,
            attrCode: item.fieldCode,
            attrValue: list[item.fieldCode],
          }));
          return {
            ...list,
            bigUnitCount: list.bigUnitNumber,
            smallUnitCount: list.smallUnitNumber,
            containerCode: list.containerCode,
            batchAttr,
          };
        }),
      };
      await taskCommit(data);
      this.$message({
        type: 'success',
        message: '操作成功',
      });
      this.onClose();
      this.$emit('onSucceed');
    },
    onClose() {
      this.dialogVisible = false;
    },
    async handleSelectTabs() {
      const {
        bigUnit, smallUnit,
        conversionRate,
      } = this.form;
      const table = await acceptanceContainerList({
        receiptOrderDetailIds: [this.form.receiptDetailId],
      });
      const data = table.map((item) => ({
        ...item,
        bigUnit,
        smallUnit,
        conversionRate,
      }));
      this.$refs.selectTabsDialog.init(data, this.formData.tableData);
    },
    handleDisbaledDate(date, index, item) {
      const today = moment();
      if (item.prop === 'expire_date') {
        // 不存在保质期 不限制
        if (!this.orderInfo.shelfLife) return false;
        const maxDate = moment(today).add(this.orderInfo.shelfLife, 'days').format(YMD);
        // 到期日期
        return moment(maxDate).isBefore(date.getTime());
      }
      if (item.prop === 'productDate') {
        // 生产日期
        return moment(today).isSameOrBefore(date.getTime());
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.header-component {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;

  .form-item {
    flex: 1 1 percentage(1 / 3);
    padding: 0;
  }
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.table-input {
  display: flex;
  align-items: center;

  .el-form-item {
    margin-bottom: 0;
  }

  .el-input-number {
    width: 60px;
  }

  .radio {
    margin-right: 12px;
  }
}

.width-100 {
  width: 100%;
}

.footer {
  padding: 0 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;

  .flex_row {
    display: flex;
    flex-direction: row;
  }

  .field {
    padding-right: 30px;

    &:last-child {
      padding-left: 50px;
    }
  }
}
</style>
<style lang="scss">
.width-100 {
  .table-date {
    width: 100%;
  }
}

.table-input {
  .is-error {
    .el-radio-group label {
      color: #f56c6c;
    }
  }
}
</style>
