<template>
  <nh-detail-page class="nh-detail-page">
    <nh-info-header
      :info="INFO"
      :data="infoData"
      title="订单基本信息"
    />
    <nh-container title="货品明细">
      <el-table :data="tableData" class="table-component">
        <el-table-column type="index" width="50" />
        <el-table-column
          v-for="item in TABLECOLUMN"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <span>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <!-- 操作栏 -->
        <nh-table-action-column width="128">
          <template #default="scope">
            <nh-button type="text" @click="handleConfirm(scope.row)">
              验收
            </nh-button>
            <nh-button type="text" @click="handleDetail(scope.row)">
              验收详情
            </nh-button>
          </template>
        </nh-table-action-column>
        <!-- / 操作栏 -->
      </el-table>
    </nh-container>
    <template #footer>
      <div class="footer">
        <span
          class="warning"
        ><i class="el-icon-warning" />验收确认表示该入库单不再到货，不可再进行验收</span>
        <nh-button
          type="primary"
          :disabled="tableData.length < 1"
          :loading="loading.handleSubmit"
          @click="handleSubmit"
        >
          验收完成
        </nh-button>
      </div>
    </template>
    <confirm-detail ref="detail" @onSucceed="getTableData" />
  </nh-detail-page>
</template>
<script>
import loadingMixin from '@thales/loading';
import unitNumber from '@/utils/unitNumber';
import ConfirmDetail from './components/detail.vue';
import { paperAcceptanceDetail, acceptancComplete } from './api';
import { DICTIONARY, TABLECOLUMN, INFO } from './fileds';
import { RECEIPT_TYPE, TEMPERATURE_LAYER } from '../fileds';
import Enum from '../utils';

export default {
  name: 'InWayehousePutaway',
  components: { ConfirmDetail },
  mixins: [loadingMixin],
  data() {
    return {
      DICTIONARY,
      TABLECOLUMN,
      INFO,
      RECEIPT_TYPE,
      id: null,
      infoData: {},
      tableData: [],
      searchQuery: {},
      loading: {
        getTableData: false,
        handleSubmit: false,
      },
    };
  },
  computed: {
    tableLoading() {
      return this.loading.getTableData || this.loading.handleSubmit;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getTableData();
  },
  methods: {
    handleQuery(data) {
      this.searchQuery = data;
      this.pagination.page = 1;
    },
    async getTableData() {
      const { taskContentResps, ...info } = await paperAcceptanceDetail({
        receiptOrderNo: this.id,
        needTask: true,
      });
      this.infoData = { ...info, receiptType: Enum(RECEIPT_TYPE)[info.receiptType] };
      this.tableData = taskContentResps.map((item) => ({
        ...item,
        temperatureLayer: TEMPERATURE_LAYER[item.temperatureLayer],
        bookingNum: this.calculateUnit(item.bookingNum, item),
        acceptanceNum: this.calculateUnit(item.acceptanceNum, item),
      }));
    },
    calculateUnit(smallUnitNumber, item) {
      return smallUnitNumber && unitNumber.calculate({ ...item, smallUnitNumber }).txt;
    },
    handleConfirm(row) {
      this.$refs.detail.init({ ...this.infoData, ...row }, 'edit');
    },
    handleDetail(row) {
      this.$refs.detail.init({ ...this.infoData, ...row });
    },
    async handleSubmit() {
      const isCompleteAcceptance = this.infoData.acceptanceNum === this.infoData.bookingTotalNum;
      try {
        await this.$confirm(`${isCompleteAcceptance ? '验收完成后，该入库单不可再验收，是否验收完成？' : '当前入库单未全部验收，完成验收后将不可再进行验收，是否确认要完成验收？'}`, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      } catch (e) {
        return;
      }
      await acceptancComplete({ receiptOrderId: this.infoData.receiptOrderId, resourceType: 'PC' });
      this.$router.push({
        path: '/in-warehouse/acceptance',
        query: {},
      });
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  border-top: 1px solid #eee;
  padding: 8px 24px 8px 0;
  background-color: #fff;
  text-align: right;
  font-size: 14px;

  .warning {
    display: inline-block;
    margin-right: 16px;
    line-height: 32px;
    padding: 0 16px;
    border-radius: 4px;
    background-color: #fffbe6;

    i {
      margin-right: 8px;
      color: #faad14;
    }
  }
}
</style>
