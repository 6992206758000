import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:纸单验收详情(货品明细)
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13250
 */
export function paperAcceptanceDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/paper_acceptance/detail', data);
}

/**
 * @description:获取验收任务详情(验收 & 验收详情公用)
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13252
 */
export function taskDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/paper_acceptance/task/detail', data);
}
/**
 * @description:验收完成
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13450
 */
export function acceptancComplete(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/acceptance/complete', data);
}
/**
 * @description:验货信息提交
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/13254
 */
export function taskCommit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/paper_acceptance/task/commit', data);
}
/**
 * @description:查询入库单对应的规则
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14122
 */
export function receiptOrderRuleDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/rule_detail', data);
}
/**
 * @description:获取库存最新生产日期
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17779
 */
export function acceptanceProductDateCheck(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt/acceptance/product_date_check', data);
}
/**
 * @description:查询货品的待验收标签
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18481
 */
export function acceptanceContainerList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/acceptance_container_list', data);
}
