import { FormType } from '@/constant/form';
import { StoreBillTypeText } from '@/constant/task';
import { formatKeyValueArray } from '@/utils/base';

const DICTIONARY = {
};
// 入库类型
const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);
const SOURCE = [{
  value: 'OMS',
  label: 'OMS',
}, {
  value: 'WMS',
  label: 'WMS',
}];
// 温层
const TEMPERATURE_LAYER = {
  NORMAL: '常温',
  COLD: '冷冻',
  REFRIGERATE: '冷藏',
  CONSTANT: '恒温',
};
export {
  DICTIONARY,
  RECEIPT_TYPE,
  SOURCE,
  TEMPERATURE_LAYER,
};
export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '入库单号',
  prop: 'receiptOrderNo',
  width: 160,
}, {
  label: '外部单号',
  prop: 'externalOrderNo',
  width: 80,
}, {
  label: '货主名称',
  prop: 'shipperName',
  width: 180,
}, {
  label: '入库单类型',
  prop: 'receiptType',
  width: 120,
}, {
  label: '车牌号',
  prop: 'plateNo',
  width: 80,
}, {
  label: '预约总数量',
  prop: 'bookingTotalNum',
  width: 120,
}, {
  label: '已验收数量',
  prop: 'acceptanceNum',
  width: 120,
}, {
  label: '待验收数量',
  prop: 'preAcceptanceNum',
  width: 160,
}, {
  label: '创建时间',
  prop: 'createTime',
  width: 180,
}, {
  label: '创建人',
  prop: 'createUserName',
  width: 80,
}, {
  label: '到货登记时间',
  prop: 'arrivalTime',
  width: 180,
}, {
  label: '最后一次验收时间',
  prop: 'endAcceptanceTime',
  width: 180,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
}];
export const serchFields = [
  {
    label: '入库单号',
    prop: 'receiptOrderNo',
    component: FormType.INPUT,
  }, {
    label: '入库单类型',
    prop: 'receiptType',
    component: FormType.SELECT,
    options: RECEIPT_TYPE,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '外部单号',
    prop: 'externalOrderNo',
    component: FormType.INPUT,
  }, {
    label: '车牌号',
    prop: 'plateNo',
    component: FormType.INPUT,
  }, {
    label: '订单来源',
    prop: 'source',
    component: FormType.SELECT,
    options: SOURCE,
  }, {
    label: '创建时间',
    prop: 'createTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },
  }, {
    label: '到货登记时间',
    prop: 'arrivalTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },
  },
];
