<!--
 * @Author: your name
 * @Date: 2022-01-10 09:40:25
 * @LastEditTime: 2022-01-10 09:40:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \fe-wms-v2-admin\src\views\in-warehouse\acceptance\confirm\components\selectTabs.vue
-->
<template>
  <div>
    <nh-dialog
      v-model="visible"
      title="选择标签"
      custom-class="custom-dialog_600"
      append-to-body
    >
      <el-table
        :data="tableData"
        class="table-component"
        @selection-change="handleSelectChange"
      >
        <el-table-column
          type="selection"
          width="50"
          :selectable="handleSelectable"
        />
        <el-table-column
          v-for="item in selectTabsColumn"
          :key="item.prop"
          :show-overflow-tooltip="true"
          v-bind="item"
        />
      </el-table>
      <template #footer>
        <div class="custom-footer">
          <div class="msg">
            <span>待验收标签数：{{ acceptanceNumber }}</span>
            <span>已选：{{ selectTable.length }}</span>
          </div>
          <div class="btn">
            <nh-button type="primary" @click="handleSave">
              确认
            </nh-button>
            <nh-button type="grey" @click="handleClose">
              取消
            </nh-button>
          </div>
        </div>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import { selectTabsColumn } from './fileds';
import { PRE_ACCEPT } from './constant';

export default {
  name: 'InWarehouseComfirmSelect',
  emits: ['onSelect'],
  data() {
    return {
      selectTabsColumn,
      tableData: [],
      selectTable: [],
      disabledIds: [], // 禁止重复选择
      visible: false,
    };
  },
  computed: {
    acceptanceNumber() {
      return this.tableData.reduce(
        (prev, curr) => (curr.status === PRE_ACCEPT ? prev + 1 : prev),
        0,
      );
    },
  },
  methods: {
    init(data, disabledTable) {
      this.visible = true;
      this.tableData = data;
      this.disabledIds = [];
      disabledTable.forEach((item) => {
        if (item.id) {
          this.disabledIds.push(item.id);
        }
      });
    },
    handleSelectChange(selectTable) {
      this.selectTable = selectTable;
    },
    handleSelectable(row) {
      return row.status === PRE_ACCEPT && !this.disabledIds.includes(row.id);
    },
    handleSave() {
      this.$emit('onSelect', this.selectTable);
      this.handleClose();
    },
    handleClose() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.title-box {
  display: flex;

  .title-content {
    font-size: 18px;
    font-weight: 500;
    color: #171717;
  }
}

.custom-footer {
  display: flex;
  justify-content: space-between;

  span {
    padding-right: 16px;
  }
}
</style>
