const DICTIONARY = {
  REALITY: 'realityNum',
};
export { DICTIONARY };
export const INFO = [{
  label: '入库单号：',
  prop: 'receiptOrderNo',
}, {
  label: '货主名称：',
  prop: 'shipperName',
}, {
  label: '入库单类型：',
  prop: 'receiptType',
}, {
  label: '备注：',
  prop: 'remark',
  width: 3,
}];
export const TABLECOLUMN = [{
  label: '货品编码',
  prop: 'goodsCode',
  width: 120,
}, {
  label: '商品名称',
  prop: 'goodsName',
  width: 80,
}, {
  label: '温层',
  prop: 'temperatureLayer',
  width: 80,
}, {
  label: '规格',
  prop: 'spec',
  width: 80,
}, {
  label: '保质期',
  prop: 'shelfLife',
  width: 180,
}, {
  label: '预约数量',
  prop: 'bookingNumStr',
  width: 120,
}, {
  label: '已验收量',
  prop: 'acceptanceNumStr',
  width: 80,
}];
