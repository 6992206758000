const PRE_ACCEPT = 'PRE_ACCEPT';
const STATUS = {
  PRE_ACCEPT: '待验收',
  PRE_PUT_ON_SHELVES: '待上架',
  DONE_PUT_ON_SHELVES: '上架完成',
};
const RECEIPT_ORDER_COPY_CODE_TYPE = {
  BOX: 'BOX',
  GOODS: 'GOODS',
};

const IS_RETURN = {
  TRUE: '是',
  FALSE: '否',
};
const ARRIVAL_TEM = 'arrival_temperature'; // 到货温度
export {
  PRE_ACCEPT,
  STATUS,
  IS_RETURN,
  RECEIPT_ORDER_COPY_CODE_TYPE,
  ARRIVAL_TEM,
};
